import * as React from 'react';
import { useEffect } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loginImage from '../cubedai-login.jpg';
import LogoImage from '../CubedAI-Regular.png';

const defaultTheme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cubedai.com/">
        CubedAI OÜ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const baseendpoint = "https://api.cubedai.com";

  useEffect(() => {
    if (!token) {
      toast.error('Invalid or missing reset token.', {
        position: "top-right",
        autoClose: 5000,
      });
      navigate("/");
    }
  }, [token, navigate]);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    if (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber 
    ) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordError) {
      toast.error("Please meet the password requirements.");
      return;
    }

    const data = new FormData(event.currentTarget);
    const user = {
      password: data.get('password'),
    };

    try {
      const response = await fetch(`${baseendpoint}/users/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        toast.success('Password reset successful! Please sign in with your new password.', {
          position: "top-right",
          autoClose: 5000,
        });
        navigate('/');
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Password reset failed', {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error('Password reset failed', {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
  <Grid
  item
  xs={12}
  sm={8}
  md={5}
  component={Paper}
  elevation={6}
  square
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Push content to top and footer to bottom
    height: '100%', // Fill the full height of the Grid
  }}
>
  <Box
    sx={{
      my: 8,
      mx: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <img src={LogoImage} alt="CubedAI Logo" style={{ width: '30%', maxWidth: '30%' }} />
    <Typography
      component="h1"
      variant="h5"
      sx={{
        fontFamily: 'Inter, sans-serif',
        fontSize: '1.5rem',
        mt: 5,
        mb: 3,
      }}
    >
      Reset Password
    </Typography>
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="New Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={handlePasswordChange}
        error={passwordError}
        helperText={
          passwordError
            ? 'Password must be at least 8 characters long and include an uppercase letter, lowercase letter, and a number'
            : ''
        }
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, backgroundColor: '#8D00FC', color: '#D0F7D8' }}
      >
        Reset Password
      </Button>
    </Box>
  </Box>
  <Copyright sx={{ mb: 2 }} />
</Grid>

      </Grid>
    </ThemeProvider>
  );
}
