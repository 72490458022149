// EmailVerificationSuccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import LogoImage from '../CubedAI-Regular.png';



const EmailVerificationSuccess = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <div
      style={{
        textAlign: 'center',
        height: '100vh', // Ensure it covers the entire viewport
        backgroundColor: theme.palette.background.paper, // Change the entire page's background
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.text.primary, // Use readable text color
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: theme.palette.background.default, // Contrast background for the div
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Slight elevation for the div
          maxWidth: '400px',
          width: '90%',
        }}
      >
        <img src={LogoImage} alt="CubedAI Logo" style={{ width: '40%'}} />
        <h1 style={{ marginBottom: '20px' }}>Email Verified Successfully!</h1>
        <p style={{ marginBottom: '30px' }}>
          Your email has been verified. You can now log in and access all features.
        </p>
        <button
          onClick={() => navigate('/')}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.main, // Button color matches theme
            color: theme.palette.primary.contrastText,
            border: 'none',
            borderRadius: '5px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          Go to Sign In
        </button>
      </div>
    </div>
  );
};

export default EmailVerificationSuccess;
